<template>
  <page-template
    :img="{
      src: require('@/assets/montessori-1050x770.jpg'),
      lazy: require('@/assets/montessori-105x77.jpg'),
    }"
    title="Montessori"
  >
  <h2>
    PEDAGOGIKA MONTESSORI
  </h2>
  <p>
    Dr Maria Montessori (1870-1952) była włoską lekarką, antropologiem i pedagogiem. Na podstawie swoich wieloletnich doświadczeń w pracy z dziećmi zaproponowała nową metodę wychowania. Położyła w niej nacisk na swobodny rozwój dziecka, czym diametralnie zmieniła podejście do sposobu nauczania dzieci w wieku przedszkolnym i szkolnym. Przeciwstawiła się systemowi, tłumiącemu aktywność dzieci, którego symbolem była szkolna ławka. M.Montessori uważała, że głównym zadaniem edukacji jest wspieranie spontaniczności i twórczości dzieci oraz umożliwianie im wszechstronnego rozwoju. 
  </p>
  <p>
    Maria Montessori zauważyła, że w wieku przedszkolnym umysł dziecka staje się niezwykle chłonny i otwarty na zdobywanie nowych wiadomości i umiejętności. Zdolność ta pojawia się w tzw. <b>wrażliwych fazach</b>- okresach gdy nauka danych treści i umiejętności przychodzi dziecku bez trudu. Nie można ich zaplanować, nie powtarzają się, nie można ich wywołać ani przyspieszyć. Pojawiają się zgodnie z naturalnym  i indywidualnym rytmem rozwoju dziecka. Są to m.in: faza na porządek, małe przedmioty, doskonalenie zmysłów, muzykę, pisanie, czytanie, matematykę. We wrażliwej fazie dziecka pojawia się kluczowy dla uczenia się proces  <b>polaryzacji uwagi</b>. Jest to moment gdy dziecko koncentruje się na danej czynności, zatapia się w swoim zajęciu, poświęca mu całą swoją uwagę. Następuje wtedy właściwy <b>proces uczenia</b> się i rozwoju umysłu. 
  </p>
  <p>
    Dzieci w wieku przedszkolnym mają potrzebę uporządkowania świata, który je otacza, doznań, jakich doświadczają, wiedzy jaką posiadają. Chcą to wszystko poukładać, nazwać i zakorzenić się w otaczającej je rzeczywistości. Uczenie się w tym okresie odbywa się poprzez <b>działanie i operowanie konkretami</b>. Dzięki manipulowaniu określonymi przedmiotami i wykonywaniu określonych czynności dzieci widzą efekt swojej pracy. Dziecko w tym okresie uczy się także samodzielności, doskonali koordynację, ćwiczy koncentrację i samodyscyplinę.
  </p>
  <p>
    Zdaniem M. Montessori trzy podstawowe elementy warunkujące rozwój i uczenie się dziecka, zgodne z jego naturalnymi potrzebami, to:
  <ul>
    <li><b>specjalnie przygotowane otoczenie</b>- ma odpowiadać m.in. takim potrzebom dziecka jak: samodzielność (meble i sprzęty na wysokości dzieci), uporządkowanie (wszystko ma swoje miejsce), ruch (możliwość swobodnego przemieszczania się i wyboru miejsca pracy). </li>
    <li><b>odpowiedni materiał dydaktyczny</b>- pozwala na dokonywanie niezależnych wyborów (czym, gdzie i, jak długo będzie się zajmować), samodzielną pracę i kontrolowanie własnych błędów</li>
    <li><b>nauczyciel będący przewodnikiem</b>- jest pomocnikiem dziecka, prezentuje mu pomoce dydaktyczne, kieruje jego indywidualną pracą, dokonuje obserwacji- dziecko uczy się jednak samodzielnie</li>
  </ul>  
  </p>
  <p>
    Zgodnie z założeniami pedagogiki Montessori w grupie przedszkolnej znajdują się <b>dzieci w różnym wieku</b>, a każde z nich rozwija się i doskonali indywidualnie. Każde dziecko ma w danym momencie różne potrzeby i różny poziom umiejętności. Każde dziecko może uczyć się i rozwijać we własnym rytmie. Grupa mieszana wiekowo swoją strukturą przypomina rodzinę: starsze dzieci uczą się pomagania młodszym, młodsze dzieci chętnie naśladują starsze, przejmują od nich wiedzę i doświadczenie. Wszystkie realizują potrzebę kontaktów społecznych, pełnią różne role społeczne, uczą się komunikowania z innymi.
  </p>
  </page-template>
</template>
<script>
import PageTemplate from "../components/PageTemplate.vue";
export default {
  components: { PageTemplate },
};
</script>
